.marketing-theme {
  --space1: 8px;
  --space2: 12px;
  --space3: 16px;
  --space4: 20px;
  --space5: 24px;
  --space6: 32px;
  --space7: 40px;
  --space8: 56px;
  --space9: 64px;
  --space10: 96px;
  --space11: 128px;
  --space12: 196px;

  --fontSize-extraLargeHeading: 3.5rem;
  --fontSize-largeHeading: 2.5rem;
  --fontSize-mediumHeading: 2rem;
  --fontSize-smallHeading: 1.5rem;
  --fontSize-extraSmallHeading: 1.25rem;
  --fontSize-largeText: 1.25rem;
  --fontSize-mediumText: 1.125rem;
  --fontSize-extraSmallText: 0.75rem;
  --fontSize-smallText: 0.875rem;

  --fontWeight-extraLargeHeading: 700;
  --fontWeight-largeHeading: 700;
  --fontWeight-mediumHeading: 700;
  --fontWeight-smallHeading: 700;
  --fontWeight-extaSmallHeading: 700;
  --fontWeight-tinyHeading: 700;
}

@media (max-width: 640px) {
  .marketing-theme {
    --space1: 4px;
    --space2: 8px;
    --space3: 12px;
    --space4: 16px;
    --space5: 20px;
    --space6: 24px;
    --space7: 32px;
    --space8: 40px;
    --space9: 48px;
    --space10: 64px;
    --space11: 96px;
    --space12: 128px;

    --fontSize-extraLargeHeading: 3rem;
    --fontSize-largeHeading: 2rem;
    --fontSize-mediumHeading: 1.5rem;
    --fontSize-smallHeading: 1.25rem;
    --fontSize-extraSmallHeading: 1rem;
    --fontSize-tinyHeading: 0.875rem;
    --fontSize-largeText: 1rem;
    --fontSize-mediumText: 0.875rem;
    --fontSize-smallText: 0.75rem;
  }
}
